define('common/components/comet/behaviors/actions',[
        'i18next',
        'app',
        'vue',
        'common/components/vue-wrapper/helpers/mount-vue-component',
        'common/components/modals/views/save',
        'common/components/modals/views/builder',
        'common/components/modals/views/share',
        '@discoveryedu/platform-components',
        '@discoveryedu/nebula-components'
    ], function (i18next, app, Vue, mountVueComponent, SaveModal, BuilderModal, ShareModal, PlatformComponents, NebulaComponents){
    "use strict";

    return Mn.Behavior.extend({

        "playerModalXHR": null,

        "channels": {
            "quickList": Backbone.Radio.channel('quickmenu:add'),
            "subscribedChannels": Backbone.Radio.channel('subscribedChannels')
        },

        "events": {
            "click .action-quick-list": "addToQuickList",
            "click .action-save": "clickSave",
            "click .action-assign": "assignModal",
            "click .js-comet-modal__button--takeover-close": "closeTakeOverModal",
            "click .action-builder": "builderModal",
            "click .action-studio": "clickCreateWithStudioModal",
            "click .action-share": "clickShare",
            "click .action-subscribe": "toggleSubscribeChannel",
        },

        "initialize": function(){

            document.addEventListener('actionMenu', _.bind(this.onAction, this));

            this.listenTo(this.channels.quickList, {
                'success remove': this.toggleQuickListState
            });
            this.listenTo(this.channels.quickList, {
                'loaded reset': this.configureAddToQuickListState
            });
            if (this.channels.subscribedChannels.request("isLoaded")){
                this.configureSubscribedChannelsState();
            } else {
                this.listenTo(this.channels.subscribedChannels, {
                    'loaded': this.configureSubscribedChannelsState
                });
            }
            this.listenTo(this.channels.subscribedChannels, {
                'change': this.configureSubscribedChannelsState
            });

        },

        // 2022 called from vue, so no longer bound to css classes
        /* eslint-disable complexity */
        "onAction": function(e) {
            var asset = e.detail.asset,
                typeName = asset.type_name || asset.type && asset.type.name || "",
                useSmoothScroll = e.detail.useSmoothScroll,
                modalClass = e.detail.modalClass;
            switch (e.detail.type) {
                case 'subscribe':
                    this.channels.subscribedChannels.trigger('subscribe', asset);
                    break;

                case 'unsubscribe':
                    this.channels.subscribedChannels.trigger('unSubscribe', asset);
                    break;

                case 'quicklist:remove':
                case 'quicklist:add':
                    this.channels.quickList.trigger('save', {
                        'assetTitle': asset.title || asset.name,
                        'assetType': typeName,
                        'assetGuid': asset.id
                    });
                    break;
                case 'studio:create':
                    this.createWithStudioModal({
                        "assetGuid": asset.id,
                        "assetTitle": asset.title || asset.name,
                        "studioFormActionPath": window.paths.studio,
                    });
                    break;
                case 'share':
                    this.shareModal({
                        "assetId": asset.id,
                        "assetType": typeName,
                        "assetTitle": asset.title || asset.name,
                        "assetDescription": asset.description || '',
                        "ssoUrl": app.initData.get("sso_url")
                    });
                    break;
                case 'save':
                    this.saveModal({
                        "selectedAssetId": asset.child_revision_id || asset.id,
                        "selectedAssetTitle": asset.title || asset.name,
                        "actions": [],
                        "sharingEnabled": app.initData.get("product_settings").SHARING,
                        "requestingResource": "core"
                    });
                    break;
                case 'playerPreview':
                    this.openPlayerModal(asset, useSmoothScroll, modalClass);
                    break;
            }
        },
        /* eslint-enable complexity */

        "onRedraw": function(){
            this.configureAddToQuickListState();
            this.configureSubscribedChannelsState();
        },

        "toggleQuickListState": function(prompt) {
            this.$(".action-quick-list").each(function(i, el){
                var $el = $(el),
                    data = $el.data();

                if (data.guid === prompt.id) {
                    $el.toggleClass('active', prompt.active);
                    if (prompt.active) {
                        $el.find(".action-tool-tip--quicklist, .dad-asset-toolbar__label, .action-quick-list__text").text(i18next.t("Remove from Favorites"));
                    } else {
                        $el.find(".action-tool-tip--quicklist, .dad-asset-toolbar__label, .action-quick-list__text").text(i18next.t("Add to Favorites"));
                    }
                }
            });
        },

        "toggleSubscribeChannel": function(e){
            var $currentTarget = $(e.currentTarget),
                channelData = $currentTarget.data(),
                isSubscribed = _.find(this.channels.subscribedChannels.request("assets"), function(channel){
                    return Object.keys(channel)[0] === channelData.guid;
                });
            if (isSubscribed) {
                this.channels.subscribedChannels.trigger('unSubscribe', channelData);
            } else {
                this.channels.subscribedChannels.trigger('subscribe', channelData);
            }
        },

        "addToQuickList": function(e) {
            var data = $(e.currentTarget).data();
            this.channels.quickList.trigger('save', {
                'assetTitle': data.title,
                'assetType': data.type,
                'assetGuid': data.guid,
                'assetThumbnail': data.thumbnail
            });
        },

        "configureAddToQuickListState": function() {
            var quickListChannel = this.channels.quickList,
                qLAssets = quickListChannel.request('assets');
            this.$(".action-quick-list").each(function(i, el){
                var $el = $(el),
                    data = $el.data(),
                    matchedAssets = _(qLAssets).filter(function(id) {
                        return id.toLowerCase() === data.guid;
                    }, this);
                if (!!matchedAssets.length) {
                    $el.find(".action-tool-tip--quicklist, .dad-asset-toolbar__label, .action-quick-list__text").text(i18next.t("Remove from Favorites"));
                    $el.addClass('active');
                } else {
                    $el.find(".action-tool-tip--quicklist, .dad-asset-toolbar__label, .action-quick-list__text").text(i18next.t("Add to Favorites"));
                    $el.removeClass('active');
                }
            });
        },

        "configureSubscribedChannelsState": function(){
            var subscribedChannels = this.channels.subscribedChannels,
                subscribedChannelAssets = subscribedChannels.request('assets');
            this.$(".action-subscribe:not(.quicklist__action-bar-item)").each(function(i, el){
                var $el = $(el),
                    data = $el.data(),
                    // collections are models/objects so the channel data has a shape like:
                    // { this-is-a-guid: {meta: data, related: to, the: event} }
                    matchedAssets = _(subscribedChannelAssets).filter(function(asset) {
                        return Object.keys(asset)[0] === data.guid;
                    });

                if (!!matchedAssets.length) {
                    $el.addClass('active');
                } else {
                    $el.removeClass('active');
                }
            });
        },

        "clickSave": function(e) {
            var data = $(e.currentTarget).data();
            this.saveModal({
                "selectedAssetId": data.guid,
                "selectedAssetTitle": data.title,
                "actions": [],
                "sharingEnabled": app.initData.get("product_settings").SHARING,
                "requestingResource": "core"
            });
        },

        "saveModal": function(assetData) {
            var $el = $(document.body);

            $el.append('<div id="my-content-modal"></div>');

            this.myContentModal = mountVueComponent(
                PlatformComponents.MyContentModal,
                '#my-content-modal',
                {
                    "asset": assetData,
                    "config": {
                        "isActive": true,
                        "nebulaSvg": window.paths.edde_static + "node_modules/@discoveryedu/nebula-icons/nebula.svg"
                    },
                    "onCloseModal": _.bind(this.destroyMyContentModal, this)
                }
            );
        },

        "destroyMyContentModal": function() {
            if (this.myContentModal) {
                this.myContentModal.unmount();
                $('#my-content-modal').remove();
            }
        },

        "assignModal": function(e) {
            window.location.href = '/learn/assign/' + $(e.currentTarget).data('guid') + '?back=' + encodeURIComponent(window.location.href);
        },

        "closeTakeOverModal": function(e){
            e.stopPropagation();
            e.preventDefault();
            if (this.currentTakeOverModalApp){
                var openerElement = this.currentTakeOverModalApp.openerElement;
                this.currentTakeOverModalApp.destroy();
                openerElement.focus();
            }
        },

        "builderModal": function(e) {
            var data = $(e.currentTarget).data(),
                request = '/core:classrooms/choose/assetGuids/' + data.guid
                + '/assetTitle/' + data.title
                + '/assetTypeGuid/' + data.type;
            $.get(request).done(function(response) {
                var modal = new BuilderModal({
                    "responseHTML": response,
                    "clickedItem": e.currentTarget
                });
                modal.render();
            });
        },

        "clickCreateWithStudioModal": function(e) {
            var data = $(e.currentTarget).data();
            this.createWithStudioModal({
                "assetGuid": data.guid,
                "assetTitle": data.title,
                "studioFormActionPath": window.paths.studio,
            });
        },

        "createWithStudioModal": function(modalProps) {
            var $el = $(document.body);

            $el.append('<div id="create-with-studio-modal"></div>');

            this.newCreateWithStudioModal = mountVueComponent(
                PlatformComponents.CreateWithStudioModal,
                '#create-with-studio-modal',
                {
                    "options": {
                        "assetGuid": modalProps.assetGuid,
                        "headline": modalProps.assetTitle,
                        "studioFormActionPath": window.paths.studio,
                    },
                    "onCloseModal": _.bind(this.destroyNewCreateWithStudioModal, this)
                }
            );
        },

        "destroyNewCreateWithStudioModal": function(){
            if (this.newCreateWithStudioModal) {
                this.newCreateWithStudioModal.unmount();
                $('#create-with-studio-modal').remove();
            }
        },

        "clickShare": function(e) {
            var $el = $(e.currentTarget),
                data = $el.data(),
                dataPath = data.path || typeof data.get === "function" && data.get("link"),
                dataLink = $("a[data-asset-id='" + data.guid + "']"),
                dataAssetType = dataLink.data("asset-type-name");

            this.shareModal({
                "assetId": data.guid,
                "assetUrl": dataPath,
                "assetType": dataAssetType,
                "assetTitle": data.title || '',
                "assetDescription": data.description || '',
                "ssoUrl": app.initData.get("sso_url")
            });
        },

        "shareModal": function(assetProps) {
            var $el = $(document.body),
                isStudent = app.initData.get("user").is_student;

            $el.append('<div id="platform-share-modal"></div>');

            this.newShareModal = mountVueComponent(
                PlatformComponents.ShareModal,
                '#platform-share-modal',
                {
                    "asset": assetProps,
                    "config": {
                        "isStudent": isStudent
                    },
                    "onCloseModal": _.bind(this.destroyNewShareModal, this)
                }
            );
        },

        "destroyNewShareModal": function(){
            if (this.newShareModal) {
                this.newShareModal.unmount();
                $('#platform-share-modal').remove();
            }
        },

        "openPlayerModal": function(asset, useSmoothScroll, modalClass) {
            var self = this;
            var $el = $(document.body);
            $el.append('<div id="player-modal-wrapper"><div id="player-modal"></div></div>');
            var url = "/learn/player/" + asset.id;

            if (this.playerModalXHR && this.playerModalXHR.readystate !== 4) {
                this.playerModalXHR.abort();
            }
            this.playerModalXHR = $.ajax({
                "url": url,
                "error": function() {
                    // navigate to url if ajax failed
                    // the server rendered page should show an applicable error
                    window.location.href = url;
                },
                "success": _.bind(function(data) {
                    if (!data.page_data) {
                        // navigate to url if there is no page data
                        window.location.href = url;
                    } else {
                        require([
                            '@discoveryedu/platform-pages/VideoPlayerPage',
                            '@discoveryedu/platform-pages/PlayerPage'
                        ], _.bind(function(VideoPlayerPage, PlayerPage) {
                            var pageData = data.page_data,
                                playerPageProps = Vue.reactive({
                                    "baseUrl": window.paths.app_url,
                                    "backUrl": window.paths.app_url + window.location.pathname + window.location.search,
                                    "isPreviewModal": true,
                                    "windowPaths": window.paths,
                                    "quicklistIds": [],
                                    "subscribedChannelIds": [],
                                    "eventBusCallback": function(eventBus) {
                                        eventBus.on("navigateToPlayerPage", function(passUrl) {
                                            self.navigateToPlayerPage(passUrl, {
                                                "page_data": data.page_data,
                                                "init_data": data.init_data,
                                                "url": passUrl
                                            });
                                        });

                                        eventBus.on("close-modal", function() {
                                            self.destroyPlayerModal();
                                        });
                                    }
                                });

                            this.playerModal = mountVueComponent(
                                NebulaComponents.NebulaModal,
                                "#player-modal-wrapper",
                                {
                                    "size": "xl",
                                    "id": "player-modal",
                                    "pageParentId": "scroll-content",
                                    "firstFocusedElement": "player-preview-title",
                                    "takeover": true,
                                    "onCloseModal": _.bind(this.destroyPlayerModal, this)
                                },
                                {
                                    "content": function() {
                                        if (pageData.video) {
                                            var accessType = 1;
                                            if (pageData.access_type) {
                                                accessType = pageData.access_type;
                                            }
                                            return Vue.h(VideoPlayerPage, _.extend(
                                                playerPageProps,
                                                {
                                                    "video": pageData.video,
                                                    "accessType": accessType,
                                                }
                                            ));
                                        }

                                        return Vue.h(PlayerPage, _.extend(
                                            playerPageProps,
                                            {
                                                "pageData": pageData,
                                                "codingUrl": window.paths.coding,
                                            }
                                        ));
                                    }
                                }
                            );

                            if (Backbone.Radio.channel('subscribedChannels') && Backbone.Radio.channel('subscribedChannels').request('assets')) {
                                playerPageProps.subscribedChannelIds = Backbone.Radio.channel('subscribedChannels').request('assets').map(function(channelObj) {
                                    return Object.keys(channelObj)[0];
                                });
                            }

                            playerPageProps.quicklistIds = _.clone(Backbone.Radio.channel('quickmenu:add').request('assets'));

                            this.listenTo(Backbone.Radio.channel('quickmenu:add'), 'change', function() {
                                playerPageProps.quicklistIds = _.clone(Backbone.Radio.channel('quickmenu:add').request('assets'));
                            });

                            if (useSmoothScroll) {
                                setTimeout(function() {
                                    window.scrollTo({
                                        'behavior': 'smooth',
                                        'top': 0
                                    });
                                }, 100);
                            }

                            if (modalClass) {
                                $('#player-modal').addClass(modalClass);
                            }

                        }, this));
                    }
                }, this)
            });
        },

        "destroyPlayerModal": function(){
            this.playerModal && this.playerModal.unmount();
            $("#player-modal-wrapper").remove();
        },

        "navigateToPlayerPage": function(url, data) {
            this.destroyPlayerModal();
            Backbone.Radio.channel('learn-router').replyOnce('router:next-skip-load', data);
            Backbone.Radio.channel('learn-router').trigger('router:navigate', url);
        }
    });
});

